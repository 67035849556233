import React, { Component } from 'react';
import { branch } from 'baobab-react/higher-order';
import _ from 'lodash';
import actions from '../../data/actions/actions';
import Thermostat from './thermostat';
import updateForm, { loadFormData } from '../../data/actions/formStateController';
import saveFormData from '../../data/actions/formActions';
import showHeaderNotification from '../../utils/showNotification';
import WarningDialog from '../../components/warningDialog';
import { requestData } from '../../data/newCommManager';

import baobabTree from '../../data/state';

// import offImg from "../../assets/img/offThermostat.png";
// import onImg from "../../assets/img/onThermostat.png";

const getUrlParameterByName = (paramname, url) => {
    if (!url) url = window.location.href;
    paramname = paramname.replace(/[[\]]/g, '\\$&');
    var regex = new RegExp('[?&]' + paramname + '(=([^&#]*)|&|#|$)'),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
};

class SingleThermostatControl extends Component {
    constructor() {
        super();
        this.state = {
            away: false,
            targetTemperature: 22, //targeted temperature
            ambientTemperature: 11, //current temperatura
            hvacMode: 'heating', //off, heating, cooling
            leaf: false,
            isThermostatOn: true,
            lockShown: false,
            isWarningShown: false,
            message: '',
            selectedFanSpeed: 0,
        };
    }

    componentDidMount() {
        let deviceId = parseInt(getUrlParameterByName('deviceId'), 10);
        this.loadData(deviceId);
    }

    loadData(deviceId) {
        let targetTemperatureIfInScene = 0;

        if (_.includes(this.props.route.location.pathname.toLowerCase(), 'scene')) {
            var arrayToSearch = [];
            if (this.props.sceneParameter === 'active') {
                arrayToSearch = this.props.sceneForm.params.sceneActiveDeviceState;
            } else {
                arrayToSearch = this.props.sceneForm.params.sceneExitDeviceState;
            }
            targetTemperatureIfInScene =
                _.find(arrayToSearch, { deviceId: parseInt(getUrlParameterByName('deviceId'), 10) }).value / 10;
            // displayValue = (sliderValue * 100) / deviceFromDb.address.analogOutputInfo.max
        }

        var device = _.find(this.props.devices, { id: parseInt(deviceId, 10) });
        let thState = _.clone(device.states);
        if (thState === undefined) {
            thState = {
                currentTemperature: 0,
                targetTemperature: 0,
                pirState: 'closed',
            };
        }
        thState.thermostatMode = device.address.thermostatMode ? device.address.thermostatMode : 'heating';

        //console.log(thState.toggleButtonBitMask, isThermostatOn);
        this.setState({
            targetTemperature: _.isUndefined(this.props.sceneParameter)
                ? thState.targetTemperature
                : targetTemperatureIfInScene,
            ambientTemperature: thState.currentTemperature,
            leaf: thState.pirState === 'open',
            hvacMode: thState.thermostatMode,
            selectedFanSpeed: thState.selectedFanSpeed,
        });
    }

    showLockOption() {
        this.setState({
            lockShown: this.state.lockShown === true ? false : true,
        });
    }

    roundToHalf(value) {
        var converted = parseFloat(value); // Make sure we have a number
        let multiplier = 1;
        if (value < 0) {
            multiplier = -1;
        }
        converted = Math.abs(converted);
        var decimal = converted - parseInt(converted, 10);
        decimal = Math.round(decimal * 10);
        if (decimal === 5) {
            return (parseInt(converted, 10) + 0.5) * multiplier;
        }
        if (decimal < 3 || decimal > 7) {
            return Math.round(converted) * multiplier;
        } else {
            return (parseInt(converted, 10) + 0.5) * multiplier;
        }
    }

    setThermostatValue(device) {
        let minValue = 5;
        let maxValue = 35;
        var dif = maxValue - minValue;

        if (dif !== 0) {
            let valueToSend = this.state.targetTemperature * 10; //(255 / dif) * (this.state.targetTemperature - minValue)

            if (!_.includes(this.props.route.location.pathname.toLowerCase(), 'scene')) {
                if (_.isUndefined(device.lockState) || device.lockState === false) {
                    actions.setAnalogOutputState(device.id, parseInt(Math.round(valueToSend), 10)).then((res) => {
                        this.props.onHideDialog();
                    });
                } else {
                    this.setState({
                        isWarningShown: true,
                        message: 'Device is locked. Cannot change state at this moment',
                    });
                }
            } else {
                let deviceId = parseInt(getUrlParameterByName('deviceId'), 10);
                var obj = {
                    deviceId: parseInt(getUrlParameterByName('deviceId'), 10),
                    value: parseInt(Math.round(valueToSend), 10),
                };

                var selection =
                    this.props.sceneParameter === 'active'
                        ? _.clone(this.props.sceneForm.params.sceneActiveDeviceState)
                        : _.clone(this.props.sceneForm.params.sceneExitDeviceState);

                var myObj = _.find(selection, (obj) => {
                    return obj.deviceId === deviceId;
                });

                selection[_.indexOf(selection, myObj)] = obj;

                var params = {};
                var ob = { params };
                if (this.props.sceneParameter === 'active') {
                    ob.params['sceneActiveDeviceState'] = selection;
                    ob.params['sceneExitDeviceState'] = this.props.sceneForm.params.sceneExitDeviceState;
                    ob.params['groups'] = this.props.sceneForm.params.groups;
                    updateForm('scene', ob);
                } else {
                    ob.params['sceneActiveDeviceState'] = this.props.sceneForm.params.sceneActiveDeviceState;
                    ob.params['sceneExitDeviceState'] = selection;
                    ob.params['groups'] = this.props.sceneForm.params.groups;
                    updateForm('scene', ob);
                }
                this.props.onHideDialog();
            }
        }
    }

    increase(maxValue) {
        if (parseFloat(this.state.targetTemperature) < maxValue) {
            this.setState({
                targetTemperature: this.state.targetTemperature + 0.5,
            });
        }
    }
    decrease(minValue) {
        if (parseFloat(this.state.targetTemperature) > minValue) {
            this.setState({
                targetTemperature: this.state.targetTemperature - 0.5,
            });
        }
    }

    turnThermostatOnOrOff(device) {
        let states = _.clone(device.states);
        let { toggleButtonBitMask } = states;
        var mask = 1 << 2; //POWER button
        toggleButtonBitMask ^= mask;

        let deviceClone = _.cloneDeep(device);
        deviceClone.states.toggleButtonBitMask = toggleButtonBitMask;

        baobabTree.root.select(['model', 'devices', { id: device.id }]).set(deviceClone);
        requestData('/setDeviceValue/' + device.id, 'PUT', { value: { toggleButtonBitMask } })
            .then((res) => {
                //console.log("RESULT:",res);
            })
            .catch((err) => {
                console.warn(err);
            });
    }

    onRangeChange(e) {
        this.setState({
            targetTemperature: this.roundToHalf(e.target.value, 10),
        });
    }
    lockState(deviceId, lockState, e) {
        let currentStateValue = _.isUndefined(lockState) ? true : !lockState;

        loadFormData('device', parseInt(deviceId, 10));

        var obj = {};
        obj['lockState'] = currentStateValue;
        updateForm('device', obj);

        saveFormData('device').then((res) => {
            showHeaderNotification(true, 3000, 'Device state ' + (currentStateValue === true ? '' : 'un') + 'locked');
        });
    }

    lockInterface(deviceId, lockHWInterface = 0) {
        let currentStateValue = lockHWInterface == 0 ? false : true;

        loadFormData('device', parseInt(deviceId, 10));

        var obj = {};
        obj['lockHWInterface'] = currentStateValue;
        updateForm('device', obj);

        saveFormData('device').then((res) => {
            showHeaderNotification(
                true,
                3000,
                'Device state ' + (currentStateValue == true ? '' : 'un') + 'locked hardwere interface'
            );
        });
    }

    hideWarning() {
        this.setState({
            isWarningShown: false,
            message: '',
        });
    }

    switchFanSpeed(device) {
        let states = _.clone(device.states);
        let selectedFanSpeed = this.state.selectedFanSpeed;
        let nextState = selectedFanSpeed === 4 ? 1 : selectedFanSpeed + 1;
        requestData('/setDeviceValue/' + device.id, 'PUT', { value: { selectedFanSpeed: nextState } })
            .then((res) => {
                //console.log("RESLT:", res);
            })
            .catch((err) => {
                console.warn(err);
            });
        this.setState({
            selectedFanSpeed: nextState,
        });
    }

    render() {
        //console.log("TU SAM MMASMDM", parseInt(getUrlParameterByName("deviceId"), 10));
        var device = _.find(this.props.devices, { id: parseInt(getUrlParameterByName('deviceId'), 10) });
        //console.log(deviceFromDb);
        if (_.isUndefined(device)) {
            return null;
        }

        let isFullScreen = false;
        if (window.innerHeight === window.screen.height) {
            // browser is fullscreen
            isFullScreen = true;
        }
        //console.log(device);
        let maxRange = device.address.analogOutputInfo ? device.address.analogOutputInfo.max : 35;
        let minRange = device.address.analogOutputInfo ? device.address.analogOutputInfo.min : 5;

        let { states } = device;
        let isPoweredOff = (states.toggleButtonBitMask & (1 << 2)) === 0;
        let isThermostatOn = !isPoweredOff;

        // console.log(states.toggleButtonBitMask, isThermostatOn);

        let fanSpeedVisual;

        switch (this.state.selectedFanSpeed) {
            case 1:
                fanSpeedVisual = (
                    <span>
                        <i className="fa fa-square" style={{ fontSize: '0.7em' }} aria-hidden="true"></i>{' '}
                        <i className="fa fa-square-o" style={{ fontSize: '1.1em' }} aria-hidden="true"></i>{' '}
                        <i className="fa fa-square-o" style={{ fontSize: '1.5em' }} aria-hidden="true"></i>
                    </span>
                );
                break;
            case 2:
                fanSpeedVisual = (
                    <span>
                        <i className="fa fa-square" style={{ fontSize: '0.7em' }} aria-hidden="true"></i>{' '}
                        <i className="fa fa-square" style={{ fontSize: '1.1em' }} aria-hidden="true"></i>{' '}
                        <i className="fa fa-square-o" style={{ fontSize: '1.5em' }} aria-hidden="true"></i>
                    </span>
                );
                break;
            case 3:
                fanSpeedVisual = (
                    <span>
                        <i className="fa fa-square" style={{ fontSize: '0.7em' }} aria-hidden="true"></i>{' '}
                        <i className="fa fa-square" style={{ fontSize: '1.1em' }} aria-hidden="true"></i>{' '}
                        <i className="fa fa-square" style={{ fontSize: '1.5em' }} aria-hidden="true"></i>
                    </span>
                );
                break;
            case 4:
                fanSpeedVisual = <span style={{ fontSize: '1em' }}>AUTO</span>;
                break;
            default:
                fanSpeedVisual = null;
                break;
        }

        return (
            <div className={isFullScreen ? 'analog-output-control blinds top70' : 'analog-output-control blinds'}>
                <WarningDialog
                    message={this.state.message}
                    hideWarning={this.hideWarning.bind(this)}
                    isShown={this.state.isWarningShown}
                />

                <div
                    className="thermostat-middle analog-output-middle blinds animated faster zoomIn"
                    id="thermostat-background"
                >
                    <i
                        onClick={this.props.onHideDialog}
                        className="fa fa-times pull-right close slider-close pointer clr-ececec"
                        aria-hidden="true"
                    ></i>
                    <i
                        onClick={this.showLockOption.bind(this)}
                        className={
                            _.includes(this.props.route.location.pathname.toLowerCase(), 'scene')
                                ? 'hidden'
                                : 'pointer fa fa-cogs lock-icon-style'
                        }
                    ></i>
                    <button
                        onClick={this.lockState.bind(this, device.id, device.lockState)}
                        className={
                            this.state.lockShown === true
                                ? 'btn btn-default lock-option animated zoomIn analog-device-lock-option'
                                : 'hidden'
                        }
                    >
                        {device.lockState === true ? 'UNLOCK STATE' : 'LOCK STATE'}
                    </button>
                    <button
                        onClick={this.lockInterface.bind(this, device.id, !device.lockHWInterface)}
                        className={
                            this.state.lockShown === true
                                ? 'btn btn-default lock-option animated zoomIn analog-device-lock-option'
                                : 'hidden'
                        }
                        style={{ marginTop: '50px' }}
                    >
                        {device.lockHWInterface === true ? 'UNLOCK Interface' : 'LOCK Interface'}
                    </button>
                    {/* <button onClick={this.lockInterface.bind(this, device.id, 1 )}
          className={this.state.lockShown === true ? "btn btn-default lock-option animated zoomIn analog-device-lock-option" : "hidden"}  style={{marginTop:"50px"}}>{"LOCK Interface"}</button>
          <button onClick={this.lockInterface.bind(this, device.id, 0 )}
          className={this.state.lockShown === true ? "btn btn-default lock-option animated zoomIn analog-device-lock-option" : "hidden"}  style={{marginTop:"80px"}}>{"UNLOCK Interface"}</button> */}
                    <div style={{ paddingBottom: '1em' }}>
                        <span
                            className="text-white"
                            style={{
                                position: 'absolute',
                                width: '50%',
                                textAlign: 'center',
                                right: 0,
                                left: 0,
                                marginRight: 'auto',
                                marginLeft: 'auto',
                            }}
                        >
                            {device.name}
                        </span>
                    </div>
                    <div className="text-center position-relative">
                        <Thermostat
                            height="80%"
                            width="80%"
                            away={this.state.away}
                            targetTemperature={this.state.targetTemperature}
                            ambientTemperature={this.state.ambientTemperature}
                            hvacMode={this.state.hvacMode}
                            leaf={this.state.leaf}
                            maxValue={maxRange}
                            minValue={minRange}
                        />
                        {fanSpeedVisual ? (
                            <div
                                style={{
                                    position: 'absolute',
                                    marginTop: '-9em',
                                    textAlign: 'center',
                                    right: 0,
                                    left: 0,
                                    marginRight: 'auto',
                                    marginLeft: 'auto',
                                }}
                            >
                                <div
                                    onClick={this.switchFanSpeed.bind(this, device)}
                                    className="btn btn-md shutters-max-btns"
                                    style={{ width: '6em' }}
                                >
                                    <strong>{fanSpeedVisual}</strong>
                                </div>
                            </div>
                        ) : (
                            <span></span>
                        )}
                    </div>
                    <div className=" text-white">
                        {_.includes(this.props.route.location.pathname.toLowerCase(), 'scene') ? null : (
                            <div
                                className="text-center"
                                style={{ marginTop: '-5rem', marginRight: 'auto', marginLeft: 'auto', zIndex: 10 }}
                                onClick={this.turnThermostatOnOrOff.bind(this, device)}
                            >
                                <input
                                    onChange={this.turnThermostatOnOrOff.bind(this, device)}
                                    checked={isThermostatOn}
                                    value={isThermostatOn ? 'on' : 'off'}
                                    className="toggle"
                                    type="checkbox"
                                />
                            </div>
                        )}
                    </div>

                    {isThermostatOn || _.includes(this.props.route.location.pathname.toLowerCase(), 'scene') ? (
                        <div>
                            <div className="text-center thermometer-slider termo-input-range">
                                <input
                                    type="range"
                                    step="0.5"
                                    onChange={this.onRangeChange.bind(this)}
                                    value={this.state.targetTemperature}
                                    min={minRange}
                                    max={maxRange}
                                />
                            </div>
                            <div className="text-center margin-t10" style={{ paddingBottom: '150px' }}>
                                <div
                                    onClick={this.decrease.bind(this, minRange)}
                                    className="btn btn-default shutters-max-btns"
                                >
                                    <strong>-</strong>
                                </div>
                                <div
                                    onClick={this.setThermostatValue.bind(this, device)}
                                    className=" btn btn-md shutters-max-btns"
                                >
                                    <strong>Apply</strong>{' '}
                                </div>
                                <div
                                    onClick={this.increase.bind(this, maxRange)}
                                    className="btn btn-default shutters-max-btns"
                                >
                                    <strong>+</strong>
                                </div>
                            </div>
                        </div>
                    ) : null}
                </div>
            </div>
        );
    }
}

/*
<div 
            onClick={this.decrease.bind(this, parseInt(deviceFromDb.params.thermostatMin.value, 10))} className="btn btn-default shutters-max-btns"><strong>-</strong></div>
            <div  onClick={this.setThermostatValue.bind(this, deviceFromDb)} className=" btn btn-md shutters-max-btns"><strong>Apply</strong> </div>
            <div
            onClick={this.increase.bind(this, parseInt(deviceFromDb.params.thermostatMax.value, 10))} className="btn btn-default shutters-max-btns"><strong>+</strong></div>
*/

export default branch(
    {
        //compositeDevices: ["model", "compositedevices"],
        sceneForm: ['forms', 'scene'],
        route: ['route'],
        ui: ['ui', 'showMainMenu'],
        devices: ['model', 'devices'],
    },
    SingleThermostatControl
);
